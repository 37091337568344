<ng-container *ngIf="! assinatura">
  <div id="gerador">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 instrucoes">
          <div class="divisor"></div>
          <h3>Como gerar a assinatura?</h3>
          <p>(Este tutorial funciona apenas no Google Chrome)</p>
          <p><strong>1)</strong> Preencha os campos solicitados ao lado.</p>
          <p><strong>2)</strong> Clique em “<strong>Gerar Assinatura</strong>”.</p>
          <p><strong>3)</strong> A assinatura gerada será mostrada em tela. <strong>Selecione todo o conteúdo usando
              CTRL + A (Command + A no Mac), e copie usando CTRL + C (Command + C no Mac).</strong></p>
          <p><strong>4)</strong> Clique na engrenagem no canto superior direito, clique em <strong>Ver todas as configurações</strong>, role a página
            até a seção de assinatura, clique em <strong>Criar nova</strong> e digite no campo <strong>assinatura</strong>.</p>
            <p><strong>5)</strong> Copie o conteúdo que o gerador criou (Ctrl + C) e cole na caixa de texto e cole o novo conteúdo (Ctrl + V).<br>
            </p>
        </div>
        <div class="col-sm-6 box-shadow">
          <form [formGroup]="assinaturaForm" (submit)="submitForm()">
            <div class="form-group">
              <label for="nome">Nome</label>
              <input type="text" class="form-control" formControlName="nome" required>
            </div>
            <div class="form-group">
              <label for="email">E-mail</label>
              <input type="email" class="form-control" formControlName="email" required>
            </div>
            <div class="form-group">
              <label for="cargo">Seu cargo</label>
              <!-- <select class="form-control" formControlName="cargo" required>
                <option value="Administration">Administration</option>
                <option value="CEO">CEO</option>
                <option value="Commercial">Commercial</option>
                <option value="Developer">Developer</option>
                <option value="Fullstack Developer">Fullstack Developer</option>
                <option value="Intern">Intern</option>
                <option value="IT Manager">IT Manager</option>
                <option value="Project Manager">Project Manager</option>
                <option value="Projects">Projects</option>
                <option value="Quality Analyst">Quality Analyst</option>
                <option value="Requirements Analyst">Requirements Analyst</option>
                <option value="Sales Executive">Sales Executive</option>
                <option value="UX Designer">UX Designer</option>
              </select> -->
              <input type="text" class="form-control" formControlName="cargo" required>
            </div>
            <div class="form-group">
              <label for="tel2">Celular empresarial (com DDD) (opcional)</label>
              <input type="text" class="form-control" formControlName="telefone" [dropSpecialCharacters]="false" mask="(00) 00000-0000">
            </div>
            <button type="submit" class="butt">Gerar Assinatura</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="assinatura">
  <table
    style="height: 154px; width: 545px; color: #000; font-family: 'Roboto', sans-serif;">
    <tr>
      <td style="width: 150px; text-align: center;" valign="top">
        <div style="height: 154px;">
          <img style="margin: 10px 0;" src="https://assinatura.clinicalabori.com.br/logo_assinatura.jpg"
            alt="asset-4">
        </div>
        
      </td>
      <td style="width: 270px;" valign="middle">
        <div>
          <div
            style="margin: 0; height: 120px; padding: 0 27px;">
            <div>
              <label
                style="line-height: 20px; margin: 0; padding: 0; display: block; font-size: 21px; font-weight: 700;color: #000;">{{assinaturaForm.controls.nome.value}}</label>
              <label
                style="line-height: 20px; margin: 0; padding: 0;font-size: 16px; font-weight: 300; color: #000;">{{assinaturaForm.controls.cargo.value}}</label>
              <div style="height: 1px; width: 120px; background-color: #B3B3B3; margin: 5px 0;"></div>
            </div>
            <div style="display: inline-block; margin: 0; padding: 0;">
              <div style="display: inline-block; margin: 0; padding: 0;">
                <span style="font-size: 12px; text-decoration: none; font-weight: 700; color: #000;">(48) 3372-6364</span>
              </div>
              <div style="display: inline;" *ngIf="assinaturaForm.controls.telefone.value">
                &nbsp; | &nbsp;
              </div>
              <div style="display: inline-block; margin: 0; padding: 0;">
                <a *ngIf="assinaturaForm.controls.telefone.value" target="blank" href="{{returnWhats(assinaturaForm.controls.telefone.value)}}"
                  style="font-size: 12px; text-decoration: none; font-weight: 700; color: #000;">{{assinaturaForm.controls.telefone.value}}</a>
              </div>
              <br>
              <div style="display: inline-block; margin: 0; padding: 0;">
                <span><a href="mailto:{{assinaturaForm.controls.email.value}}"
                    style="text-decoration: none; font-weight: 700; font-size: 12px; color: #000; cursor: default;">{{assinaturaForm.controls.email.value}}</a></span>
              </div>
            </div>
          </div>
        </div>

        <div style="display: block;width: 200px;margin-left: 27px;text-align: left;">
          <label style="font-weight: 500; vertical-align: top; font-size: 11px; margin-bottom: 1px;color: #000;">Redes sociais:</label>
          <span style="vertical-align: middle;"><a target="blank" style="vertical-align: sub; padding-left: 3px;"
              href="https://www.facebook.com/clinicalaborisj/"><img style="width: 15px" 
                src="https://assinatura.clinicalabori.com.br/facebook.png" alt="asset-10"></a></span>
          <span style="vertical-align: middle;"><a target="blank" style="vertical-align: sub; padding-left: 3px;"
              href="https://www.instagram.com/clinicalaborisj/"><img style="width: 15px" 
                src="https://assinatura.clinicalabori.com.br/instagram.png" alt="asset-9"></a></span>
          <span style="vertical-align: middle;"><a target="blank" style="vertical-align: sub; padding-left: 3px;"
              href="https://www.linkedin.com/in/cl%C3%ADnica-labori-clinica-labori-4b26a01b8/"><img style="width: 15px" 
                src="https://assinatura.clinicalabori.com.br/linkedin.png" alt="asset-9"></a></span>
        </div>

        <div style="padding: 0 27px;">
          <a href="https://clinicalabori.com.br/"
            style="font-weight: 300; font-size: 17px; text-decoration: none; color: #000;">clinicalabori.com.br</a>
        </div>

      </td>
    </tr>
  </table>
</ng-container>
